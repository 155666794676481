@font-face {
    font-family: "jua";
    font-display: fallback;

    src:
            url("./jua.otf") format("opentype");

    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "hanna";
    font-display: fallback;

    src: url("./hanna.otf") format("opentype");

    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "girang";
    font-display: fallback;

    src: url("./girang.otf") format("opentype");

    font-weight: 400;
    font-style: normal;
}